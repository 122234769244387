import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Unstable_Grid2 as Grid,
  CircularProgress,
} from "@mui/material";
import React, { FC, useState, useEffect, useCallback } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import useAuth from "../../hooks/useAuth";
import {
  recuperarAdvogadoPorId,
  salvarAdvogado,
  atualizarAdvogadoPorId,
} from "../../services/advogado";
import { Advogado, AxiosResponse, Response } from "../../types";

const TelaAdvogado: FC = () => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const { signout } = useAuth();
  const [isLoading, setIsLoading] = useState(false);
  const [advogado, setAdvogado] = useState<Advogado>({
    id: null,
    nome: "",
    oab: "",
    telefone: "",
    celular: "",
    email: "",
    site: "",
    ativo: "S",
  });

  useEffect(() => {
    if (id) {
      Promise.all([
        recuperarAdvogadoPorId(id)
          .then((response: AxiosResponse<Advogado>) => {
            if (response) {
              setAdvogado(response.data);
            }
          })
          .catch((error: any) => {
            if (error.response && error.response.status === 401) {
              toast.info("Desconectado por inatividade");
              signout();
              navigate("/");
            } else {
              toast.error("Erro ao recuperar advogado: " + error.message);
            }
          }),
      ]);
    }
  }, [id]);

  const handleCancelar = () => {
    navigate(-1);
  };

  const handleSalvar = () => {
    setIsLoading(true);
    const salvarOuAtualizar = id
      ? atualizarAdvogadoPorId(id, advogado)
      : salvarAdvogado(advogado);

    salvarOuAtualizar
      .then((response: AxiosResponse<Response>) => {
        if (response) {
          const message = id
            ? "Advogado atualizado com sucesso!"
            : "Advogado salvo com sucesso!";
          toast.success(message);
          navigate(-1);
        }
      })
      .catch((error) => {
        let errorMessage = "";
        if (error.response?.data?.response) {
          errorMessage += error.response.data.response;
        } else {
          errorMessage += error.message;
        }
        toast.error(errorMessage);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Layout>
      <form autoComplete="off" noValidate>
        <Card>
          <CardHeader
            subheader="Essas informações podem ser editadas"
            title={id ? "Editar" : "Cadastrar"}
          />
          <CardContent sx={{ pt: 0 }}>
            <Box sx={{ m: -1.5 }}>
              <Grid container spacing={3}>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="nome"
                    required
                    value={advogado.nome}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, nome: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Oab"
                    name="oab"
                    value={advogado.oab}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, oab: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="telefone"
                    type="number"
                    value={advogado.telefone ?? ""}
                    onChange={(event) =>
                      setAdvogado({
                        ...advogado,
                        telefone: event.target.value,
                      })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Celular"
                    name="celular"
                    value={advogado.celular}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, celular: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="E-Mail"
                    name="email"
                    value={advogado.email}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, email: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={9}>
                  <TextField
                    fullWidth
                    label="Site"
                    name="site"
                    value={advogado.site}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, site: event.target.value })
                    }
                  />
                </Grid>
                <Grid xs={12} md={3}>
                  <TextField
                    fullWidth
                    label="Ativo"
                    name="ativo"
                    select
                    SelectProps={{ native: true }}
                    value={advogado.ativo}
                    onChange={(event) =>
                      setAdvogado({ ...advogado, ativo: event.target.value })
                    }
                  >
                    <option value="S">Sim</option>
                    <option value="N">Não</option>
                  </TextField>
                </Grid>
              </Grid>
            </Box>
          </CardContent>
          <Divider />
          <CardActions sx={{ justifyContent: "flex-end" }}>
            <Button
              type="button"
              variant="contained"
              disabled={isLoading}
              onClick={handleSalvar}
            >
              {isLoading ? <CircularProgress size={24} /> : "Salvar"}
            </Button>
            <Button type="button" variant="outlined" onClick={handleCancelar}>
              Cancelar
            </Button>
          </CardActions>
        </Card>
      </form>
    </Layout>
  );
};

export default TelaAdvogado;
