import { XCircleIcon } from "@heroicons/react/24/solid";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Box,
  TextField,
  IconButton,
  Typography,
} from "@mui/material";
import { DataGrid, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAuth from "../hooks/useAuth";
import { recuperarTodosAdvogados } from "../services/advogado";
import { Advogado } from "../types";

interface AdvogadoSelectionDialogProps {
  open: boolean;
  onClose: () => void;
  onSelect: (advogado: Advogado) => void;
}

const AdvogadoSelectionDialog: React.FC<AdvogadoSelectionDialogProps> = ({
  open,
  onClose,
  onSelect,
}) => {
  const navigate = useNavigate();
  const { signout } = useAuth();
  const [advogados, setAdvogados] = useState<Advogado[]>([]);
  const [searchQuery, setSearchQuery] = useState<string>("");
  const [selectedAdvogado, setSelectedAdvogado] = useState<Advogado | null>(
    null,
  );
  const [selectedRows, setSelectedRows] = useState<GridRowSelectionModel>([]);

  useEffect(() => {
    if (open) {
      recuperarTodosAdvogados()
        .then((response) => setAdvogados(response.data))
        .catch((error: any) => {
          if (error.response && error.response.status === 401) {
            toast.info("Desconectado por inatividade");
            signout();
            navigate("/");
          } else {
            toast.error("Erro ao recuperar todos advogados: " + error.message);
          }
        });
    }
  }, [open]);

  const handleSelectClick = () => {
    if (selectedAdvogado) {
      onSelect(selectedAdvogado);
      onClose();
    } else {
      console.error("Nenhum advogado selecionado.");
    }
  };

  const filteredAdvogados = advogados.filter(
    (advogado) =>
      advogado.nome.toLowerCase().includes(searchQuery.toLowerCase()) ||
      (advogado.oab &&
        advogado.oab.toLowerCase().includes(searchQuery.toLowerCase())),
  );

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 80 },
    { field: "nome", headerName: "Nome", width: 250 },
    { field: "oab", headerName: "OAB", width: 110 },
    { field: "email", headerName: "E-Mail", width: 150 },
    {
      field: "selecionar",
      headerName: "Selecionar",
      width: 150,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="primary"
          onClick={() => setSelectedAdvogado(params.row)}
        >
          Selecionar
        </Button>
      ),
    },
  ];

  return (
    <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogTitle>
        Selecione um Advogado
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="fechar"
          sx={{
            position: "absolute",
            right: 25,
            top: 10,
            zIndex: 10,
          }}
        >
          <XCircleIcon style={{ width: 24, height: 24 }} />{" "}
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ mb: 2 }}>
          <TextField
            fullWidth
            label="Buscar por Nome ou OAB"
            variant="outlined"
            value={searchQuery}
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setSearchQuery(e.target.value)
            }
            sx={{
              top: 10,
            }}
          />
        </Box>
        <Box sx={{ height: 400, width: "100%" }}>
          <DataGrid
            rows={filteredAdvogados}
            columns={columns}
            checkboxSelection
            onRowClick={(params) => setSelectedAdvogado(params.row as Advogado)}
            getRowId={(row) => row.id}
            onRowSelectionModelChange={(newSelection) => {
              setSelectedRows(newSelection);
              if (newSelection.length === 1) {
                const selectedId = newSelection[0];
                const advogadoSelecionado = advogados.find(
                  (advogado) => advogado.id === selectedId,
                );
                setSelectedAdvogado(advogadoSelecionado || null);
              } else {
                setSelectedAdvogado(null);
              }
            }}
          />
        </Box>
        {selectedRows.length > 1 && (
          <Typography color="error" sx={{ mt: 2 }}>
            Apenas um advogado pode ser selecionado de cada vez.
          </Typography>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancelar</Button>
        <Button
          onClick={handleSelectClick}
          color="primary"
          variant="contained"
          disabled={!selectedAdvogado}
        >
          Confirmar Seleção
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AdvogadoSelectionDialog;
