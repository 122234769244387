import ArrowDownOnSquareIcon from "@heroicons/react/24/solid/ArrowDownOnSquareIcon";
import ArrowUpOnSquareIcon from "@heroicons/react/24/solid/ArrowUpOnSquareIcon";
import BriefcaseIcon from "@heroicons/react/24/solid/BriefcaseIcon";
import ChartBarIcon from "@heroicons/react/24/solid/ChartBarIcon";
import CheckCircleIcon from "@heroicons/react/24/solid/CheckCircleIcon";
// import ClockIcon from "@heroicons/react/24/solid/ClockIcon";
import CogIcon from "@heroicons/react/24/solid/CogIcon";
import DocumentTextIcon from "@heroicons/react/24/solid/DocumentTextIcon";
import EnvelopeIcon from "@heroicons/react/24/solid/EnvelopeIcon";
import ExclamationCircleIcon from "@heroicons/react/24/solid/ExclamationCircleIcon";
import FolderArrowDownIcon from "@heroicons/react/24/solid/FolderArrowDownIcon";
import InformationCircleIcon from "@heroicons/react/24/solid/InformationCircleIcon";
import MinusCircleIcon from "@heroicons/react/24/solid/MinusCircleIcon";
import NoSymbolIcon from "@heroicons/react/24/solid/NoSymbolIcon";
import UsersIcon from "@heroicons/react/24/solid/UsersIcon";
import { SvgIcon } from "@mui/material";

import useAuth from "../hooks/useAuth";

export const generateItems = () => {
  const { user } = useAuth();

  const baseItems = [
    {
      title: "Home",
      path: "/home",
      icon: (
        <SvgIcon fontSize="small">
          <ChartBarIcon />
        </SvgIcon>
      ),
      disabled: false,
      external: false,
    },
    {
      title: "Processos",
      path: "/processos",
      icon: (
        <SvgIcon fontSize="small">
          <BriefcaseIcon />
        </SvgIcon>
      ),
      disabled: false,
      external: false,
    },
    {
      title: "Advogados",
      path: "/advogados",
      icon: (
        <SvgIcon fontSize="small">
          <UsersIcon />
        </SvgIcon>
      ),
      disabled: false,
      external: false,
    },
    {
      title: "E-Mails Enviados",
      path: "/emailsenviados",
      icon: (
        <SvgIcon fontSize="small">
          <EnvelopeIcon />
        </SvgIcon>
      ),
      disabled: false,
      external: false,
    },
    // {
    //   title: "Perfil",
    //   path: `/usuario/editarUsuario/${user?.idUsuario}`,
    //   icon: (
    //     <SvgIcon fontSize="small">
    //       <UserCircleIcon />
    //     </SvgIcon>
    //   ),
    //   disabled: false,
    //   external: false,
    // },
    // {
    //   title: "Relatórios",
    //   path: "/relatorios",
    //   icon: (
    //     <SvgIcon fontSize="small">
    //       <DocumentTextIcon />
    //     </SvgIcon>
    //   ),
    //   disabled: false,
    //   external: false,
    // },
  ];

  if (user?.tipo === "ADMIN") {
    baseItems.push(
      // {
      //   title: "CSP",
      //   path: "/csps",
      //   icon: (
      //     <SvgIcon fontSize="small">
      //       <CogIcon />
      //     </SvgIcon>
      //   ),
      //   disabled: false,
      //   external: false,
      // },
      {
        title: "Importar",
        path: "/importar",
        icon: (
          <SvgIcon fontSize="small">
            <ArrowDownOnSquareIcon />
          </SvgIcon>
        ),
        disabled: false,
        external: false,
      },
      // {
      //   title: "Exportar",
      //   path: "/exportar",
      //   icon: (
      //     <SvgIcon fontSize="small">
      //       <ArrowUpOnSquareIcon />
      //     </SvgIcon>
      //   ),
      //   disabled: false,
      //   external: false,
      // },
      // {
      //   title: "Backup",
      //   path: "/backup",
      //   icon: (
      //     <SvgIcon fontSize="small">
      //       <FolderArrowDownIcon />
      //     </SvgIcon>
      //   ),
      //   disabled: false,
      //   external: false,
      // },
    );
  }

  return baseItems;
};
