import type { Advogado, AxiosResponse, Response } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_ADVOGADO = "/v1/advogado";

export async function recuperarTodosAdvogados(): Promise<
  AxiosResponse<Advogado[]>
> {
  return await painelApi.get(PATH_V1_ADVOGADO);
}

export async function salvarAdvogado(
  request: Advogado,
): Promise<AxiosResponse<Response>> {
  return await painelApi.post(PATH_V1_ADVOGADO, request);
}

export async function recuperarAdvogadoPorId(
  codigo: string,
): Promise<AxiosResponse<Advogado>> {
  return await painelApi.get(`${PATH_V1_ADVOGADO}/${codigo}`);
}

export async function atualizarAdvogadoPorId(
  codigo: string,
  request: Advogado,
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_ADVOGADO}/${codigo}`, request);
}

export async function atualizarListaDeAdvogados(
  request: Advogado[],
): Promise<AxiosResponse<Response>> {
  return await painelApi.put(`${PATH_V1_ADVOGADO}/editar-lista`, request);
}

export async function deletarAdvogadoPorId(
  codigo: string,
): Promise<AxiosResponse<Response>> {
  return await painelApi.delete(`${PATH_V1_ADVOGADO}/${codigo}`);
}

export async function deletarAdvogadoProcesso(
  advogadoId: string,
  processoId: string,
): Promise<AxiosResponse<Response>> {
  return await painelApi.delete(
    `${PATH_V1_ADVOGADO}/advogado-processo/${advogadoId}/${processoId}`,
  );
}
