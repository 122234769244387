import { Close as CloseIcon } from "@mui/icons-material";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Button,
  IconButton,
} from "@mui/material";
import React, { FC } from "react";

interface DeleteConfirmationDialogProps {
  isOpen: boolean;
  title: string;
  content: string;
  onDeleteFromProcess: () => void;
  onDeleteFromDatabase: () => void;
  onCancel: () => void;
  onClose: () => void;
}

const DeleteAdvogadoDialog: FC<DeleteConfirmationDialogProps> = ({
  isOpen,
  title,
  content,
  onDeleteFromProcess,
  onDeleteFromDatabase,
  onCancel,
  onClose,
}) => {
  return (
    <Dialog
      open={isOpen}
      onClose={onCancel}
      aria-labelledby="delete-dialog-title"
      aria-describedby="delete-dialog-description"
    >
      <DialogTitle id="delete-dialog-title">
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{
            position: "absolute",
            right: 20,
            top: 8,
          }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <p>{content}</p>
      </DialogContent>
      <DialogActions>
        <Button onClick={onDeleteFromProcess} color="primary">
          Excluir do Processo
        </Button>
        <Button onClick={onDeleteFromDatabase} color="secondary">
          Excluir do Banco de Dados
        </Button>
        <Button onClick={onCancel} color="secondary">
          Cancelar
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeleteAdvogadoDialog;
