import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Card, Tooltip, Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ProcessoContentDialog from "../components/processoContentDialog";
import useAuth from "../hooks/useAuth";
import { Advogado, AxiosResponse, Response } from "../types";
import ConfirmDialog from "./confirmDialog";

interface MuiTableProps {
  items: Advogado[];
  onDelete: (id: string) => Promise<void>;
  onSelectionChange?: (selectedIDs: string[]) => void;
}

export const TableAdvogados: React.FC<MuiTableProps> = ({
  items,
  onDelete,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  const { user, setEditUser } = useAuth();
  const [selectedIDs, setSelectedIDs] = React.useState<GridRowSelectionModel>(
    [],
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
  const [selectedProcessoContent, setSelectedProcessoContent] = useState<
    string | null
  >(null);
  const [conteudoDialogOpen, setConteudoDialogOpen] = useState(false);

  const handleEditClick = (rowData: Advogado) => {
    navigate(`/advogado/${rowData.id}`);
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel,
  ) => {
    setSelectedIDs(newSelectionModel);
    if (onSelectionChange) {
      onSelectionChange(newSelectionModel.map((id) => id.toString()));
    }
  };

  const handleDeleteClick = (id: string) => {
    setUserIdToDelete(id);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (userIdToDelete) {
      await onDelete(userIdToDelete);
      setConfirmOpen(false);
      setUserIdToDelete(null);
    }
  };

  const handleConteudoDialogClose = () => {
    setConteudoDialogOpen(false);
    setSelectedProcessoContent(null);
  };

  const columns: GridColDef[] = [
    { field: "nome", headerName: "Nome", width: 210 },
    { field: "oab", headerName: "Oab", width: 125 },
    { field: "telefone", headerName: "Telefone", width: 130 },
    { field: "celular", headerName: "Celular", width: 130 },
    { field: "email", headerName: "E-Mail", width: 180 },
    { field: "site", headerName: "Site", width: 150 },
    {
      field: "ativo",
      headerName: "Ativo",
      width: 100,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: params.value === "S" ? "green" : "#FA3F17",
                width: 120,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {params.value === "S" ? "ATIVO" : "INATIVO"}
            </Box>
          </div>
        );
      },
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      renderCell: (params) => {
        return [
          <Tooltip
            title="Editar Completo"
            arrow
            key={`${params.row.id}-edit-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-edit`}
              icon={<EditIcon />}
              label="Editar"
              className="textPrimary"
              color="inherit"
              onClick={() => handleEditClick(params.row)}
            />
          </Tooltip>,
          <Tooltip
            title="Excluir"
            arrow
            key={`${params.row.id}-delete-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-delete`}
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => handleDeleteClick(params.id.toString())}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          checkboxSelection
          pagination
          rowSelectionModel={selectedIDs}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      <ConfirmDialog
        isOpen={confirmOpen}
        title="Confirmar Exclusão"
        content="Tem certeza que deseja excluir?"
        onConfirm={handleConfirmDelete}
        onCancel={() => setConfirmOpen(false)}
      />
      <ProcessoContentDialog
        conteudo={selectedProcessoContent || ""}
        open={conteudoDialogOpen}
        onClose={handleConteudoDialogClose}
      />
    </Card>
  );
};
