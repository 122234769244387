import {
  Edit as EditIcon,
  Delete as DeleteIcon,
  Search as SearchIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { Card, Tooltip } from "@mui/material";
import { IconButton } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import { addDays, format, isValid, parse } from "date-fns";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ProcessoContentDialog from "../components/processoContentDialog";
import useAuth from "../hooks/useAuth";
import { ProcessoListDTO, AxiosResponse, Response } from "../types";
import ConfirmDialog from "./confirmDialog";

interface MuiTableProps {
  items: ProcessoListDTO[];
  onDelete: (id: string) => Promise<void>;
  onSelectionChange?: (selectedIDs: string[]) => void;
}

export const MuiTable: React.FC<MuiTableProps> = ({
  items,
  onDelete,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  const { user, setEditUser } = useAuth();
  const [selectedIDs, setSelectedIDs] = React.useState<GridRowSelectionModel>(
    [],
  );
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
  const [selectedProcessoContent, setSelectedProcessoContent] = useState<
    string | null
  >(null);
  const [conteudoDialogOpen, setConteudoDialogOpen] = useState(false);

  const handleEditClick = (rowData: ProcessoListDTO) => {
    navigate(`/processo/${rowData.id}`);
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel,
  ) => {
    setSelectedIDs(newSelectionModel);
    if (onSelectionChange) {
      onSelectionChange(newSelectionModel.map((id) => id.toString())); // Passa os IDs selecionados como strings
    }
  };

  const handleDeleteClick = (id: string) => {
    setUserIdToDelete(id);
    setConfirmOpen(true);
  };

  const handleConfirmDelete = async () => {
    if (userIdToDelete) {
      await onDelete(userIdToDelete);
      setConfirmOpen(false);
      setUserIdToDelete(null);
    }
  };

  const handleConteudoClickOpen = (conteudo: string) => {
    setSelectedProcessoContent(conteudo);
    setConteudoDialogOpen(true);
  };

  const handleConteudoDialogClose = () => {
    setConteudoDialogOpen(false);
    setSelectedProcessoContent(null);
  };

  const columns: GridColDef[] = [
    { field: "numero", headerName: "Número", width: 210 },
    {
      field: "conteudo",
      headerName: "Conteúdo",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Ver Conteúdo Completo">
            <IconButton
              onClick={() => handleConteudoClickOpen(params.row.conteudo)}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "dataCriacao",
      headerName: "Criação",
      width: 115,
      sortComparator: (v1, v2) => {
        const date1 = parse(v1, "dd/MM/yyyy", new Date());
        const date2 = parse(v2, "dd/MM/yyyy", new Date());
        return date1.getTime() - date2.getTime();
      },
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      renderCell: (params) => {
        return [
          <Tooltip
            title="Editar Completo"
            arrow
            key={`${params.row.id}-edit-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-edit`}
              icon={<EditIcon />}
              label="Editar"
              className="textPrimary"
              color="inherit"
              onClick={() => handleEditClick(params.row)}
            />
          </Tooltip>,
          <Tooltip
            title="Excluir"
            arrow
            key={`${params.row.id}-delete-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-delete`}
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => handleDeleteClick(params.id.toString())}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
    { field: "areaDescricao", headerName: "Área", width: 125 },
    { field: "classeDescricao", headerName: "Classe", width: 180 },
    { field: "varaDescricao", headerName: "Vara", width: 180 },
    { field: "assuntoDescricao", headerName: "Assunto", width: 180 },
    { field: "valor", headerName: "Valor", width: 180 },
    {
      field: "advogado1",
      headerName: "Advogado 1",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[0];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton aria-label="Ver detalhes">
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      field: "advogado2",
      headerName: "Advogado 2",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[1];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton aria-label="Ver detalhes">
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
    {
      field: "advogado3",
      headerName: "Advogado 3",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => {
        const advogado = params.row.advogados?.[2];
        return advogado ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "space-between",
              width: "100%",
            }}
          >
            <span
              style={{
                overflow: "hidden",
                textOverflow: "ellipsis",
                whiteSpace: "nowrap",
                marginRight: 8,
              }}
            >
              {advogado.nome} (OAB: {advogado.oab})
            </span>
            <IconButton aria-label="Ver detalhes">
              <SearchIcon />
            </IconButton>
          </div>
        ) : (
          "-"
        );
      },
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          checkboxSelection
          pagination
          rowSelectionModel={selectedIDs}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      <ConfirmDialog
        isOpen={confirmOpen}
        title="Confirmar Exclusão"
        content="Tem certeza que deseja excluir?"
        onConfirm={handleConfirmDelete}
        onCancel={() => setConfirmOpen(false)}
      />
      <ProcessoContentDialog
        conteudo={selectedProcessoContent || ""}
        open={conteudoDialogOpen}
        onClose={handleConteudoDialogClose}
      />
    </Card>
  );
};
