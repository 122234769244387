import PlusIcon from "@heroicons/react/24/solid/PlusIcon";
import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import ConfirmDialog from "../../components/confirmDialog";
import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import { TableAdvogados } from "../../components/tableAdvogados";
import useAuth from "../../hooks/useAuth";
import { recuperarTodosAdvogados } from "../../services/advogado";
import { deletarAdvogadoPorId } from "../../services/advogado";
import { Advogado, AxiosResponse, Response } from "../../types";

const ListagemAdvogado: FC = () => {
  const navigate = useNavigate();
  const { user, signout } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [confirmBlockOpen, setConfirmBlockOpen] = useState(false);
  const [advogados, setAdvogados] = useState<Advogado[]>([]);

  const fetchProcessos = async () => {
    try {
      const response = await recuperarTodosAdvogados();
      if (response) {
        setAdvogados(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar todos os usuários:" + error);
      }
    }
  };

  const fetchData = async () => {
    if (user?.idUsuario) {
      await Promise.all([fetchProcessos()]);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchData();
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAdd = () => {
    navigate(`/advogado`);
  };

  const handleDelete = async (id: string) => {
    try {
      Promise.all([
        deletarAdvogadoPorId(id).then((response: AxiosResponse<Response>) => {
          if (response) {
            fetchData();
            toast.success("Advogado(a) deletado(a) com sucesso!");
          }
        }),
      ]);
    } catch (error) {
      toast.error("Erro ao deletar advogado: " + error);
    }
  };

  const filteredData = advogados.filter(
    (item) =>
      item.nome.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.ativo.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  const handleCancelBlock = () => {
    setConfirmBlockOpen(false);
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">Advogados</Typography>
              </Stack>
              <div>
                <Button
                  startIcon={
                    <SvgIcon fontSize="small">
                      <PlusIcon />
                    </SvgIcon>
                  }
                  variant="contained"
                  sx={{ marginLeft: "16px" }}
                  onClick={handleAdd}
                >
                  Cadastro
                </Button>
              </div>
            </Stack>
            <Search
              placeholder={"Pesquisar por nome"}
              onChange={handleSearchChange}
            />
            <TableAdvogados items={filteredData} onDelete={handleDelete} />
          </Stack>
          <ConfirmDialog
            isOpen={confirmBlockOpen}
            title="Confirmar Bloqueio"
            content="Tem certeza que deseja bloquear este usuário e toda a sua hierarquia?"
            onConfirm={handleCancelBlock}
            onCancel={handleCancelBlock}
          />
        </Container>
      </Box>
    </Layout>
  );
};

export default ListagemAdvogado;
