import PaperClipIcon from "@heroicons/react/24/solid/PaperClipIcon";
import { Search as SearchIcon } from "@mui/icons-material";
import { Card, Tooltip, IconButton, Box } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import useAuth from "../hooks/useAuth";
import { EmailsEnviados, AxiosResponse, Response } from "../types";
import ProcessoContentDialog from "./processoContentDialog";

interface MuiTableProps {
  items: EmailsEnviados[];
  onDelete: (id: string) => Promise<void>;
  onSelectionChange?: (selectedIDs: string[]) => void;
}

export const TableEmailsEnviados: React.FC<MuiTableProps> = ({
  items,
  onDelete,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  const { user, setEditUser } = useAuth();
  const [selectedIDs, setSelectedIDs] = React.useState<GridRowSelectionModel>(
    [],
  );
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);
  const [dialogContent, setDialogContent] = useState<string | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const handleEditClick = (rowData: EmailsEnviados) => {
    navigate(`/advogado/${rowData.id}`);
  };

  const handleSelectionModelChange = (
    newSelectionModel: GridRowSelectionModel,
  ) => {
    setSelectedIDs(newSelectionModel);
    if (onSelectionChange) {
      onSelectionChange(newSelectionModel.map((id) => id.toString()));
    }
  };

  const handleDialogOpen = (content: string) => {
    setDialogContent(content);
    setDialogOpen(true);
  };

  const handleDialogClose = () => {
    setDialogContent(null);
    setDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "destinatario", headerName: "Destinatário", width: 260 },
    {
      field: "cc",
      headerName: "CC",
      width: 170,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.cc}
          </span>
          <IconButton
            onClick={() => handleDialogOpen(params.row.cc)}
            size="small"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "titulo",
      headerName: "Título",
      width: 200,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.titulo}
          </span>
          <IconButton
            onClick={() => handleDialogOpen(params.row.titulo)}
            size="small"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "mensagem",
      headerName: "Mensagem",
      width: 130,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.mensagem}
          </span>
          <IconButton
            onClick={() => handleDialogOpen(params.row.mensagem)}
            size="small"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ),
    },
    {
      field: "advogadoNome",
      headerName: "Advogado",
      width: 200,
      renderCell: (params) => (
        <Box
          display="flex"
          alignItems="center"
          justifyContent="space-between"
          width="100%"
        >
          <span
            style={{
              overflow: "hidden",
              textOverflow: "ellipsis",
              whiteSpace: "nowrap",
            }}
          >
            {params.row.advogadoNome}
          </span>
          <IconButton
            onClick={() => handleDialogOpen(params.row.advogadoNome)}
            size="small"
          >
            <SearchIcon />
          </IconButton>
        </Box>
      ),
    },
    { field: "processoNumero", headerName: "Processo", width: 210 },
    { field: "usuarioNome", headerName: "Usuário", width: 120 },
    { field: "dataEnvio", headerName: "Data de Envio", width: 140 },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      renderCell: (params) => [
        <Tooltip title="Anexos" key={`${params.row.id}-anexos-tooltip`}>
          <GridActionsCellItem
            key={`${params.row.id}-anexos`}
            icon={
              <span
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                }}
              >
                <PaperClipIcon
                  style={{ width: "20px", height: "20px", color: "gray" }}
                />
              </span>
            }
            label="Editar"
            onClick={() => handleEditClick(params.row)}
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <Card>
      <div style={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={items}
          columns={columns}
          checkboxSelection
          pagination
          rowSelectionModel={selectedIDs}
          onRowSelectionModelChange={handleSelectionModelChange}
        />
      </div>
      <ProcessoContentDialog
        conteudo={dialogContent || ""}
        open={dialogOpen}
        onClose={handleDialogClose}
      />
    </Card>
  );
};
