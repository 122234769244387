import { Edit as EditIcon, Delete as DeleteIcon } from "@mui/icons-material";
import { Box, Tooltip, Card } from "@mui/material";
import {
  DataGrid,
  GridColDef,
  GridActionsCellItem,
  GridRowSelectionModel,
} from "@mui/x-data-grid";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";

import {
  deletarAdvogadoPorId,
  deletarAdvogadoProcesso,
} from "../services/advogado";
import { Advogado, AxiosResponse, Response } from "../types";
import DeleteAdvogadoDialog from "./deleteAdvogadoDialog";

interface AdvogadoGridProps {
  advogados: Advogado[];
  selectedAdvogados: Advogado[];
  onSelectionChange: (selected: Advogado[]) => void;
}

const AdvogadoGrid: React.FC<AdvogadoGridProps> = ({
  advogados,
  selectedAdvogados,
  onSelectionChange,
}) => {
  const navigate = useNavigate();
  const { id } = useParams<{ id?: string }>();
  const [dialogOpen, setDialogOpen] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState<string | null>(null);

  const handleEditClick = (rowData: Advogado) => {
    navigate(`/advogado/${rowData.id}`);
  };

  const handleDeleteClick = (id: string) => {
    setUserIdToDelete(id);
    setDialogOpen(true);
  };

  const handleDeleteFromProcess = async () => {
    if (userIdToDelete && id) {
      try {
        Promise.all([
          deletarAdvogadoProcesso(userIdToDelete, id).then(
            (response: AxiosResponse<Response>) => {
              if (response) {
                window.location.reload();
                toast.success("Advogado(a) deletado(a) do processo!");
              }
            },
          ),
        ]);
      } catch (error) {
        toast.error("Erro ao deletar advogado do processo: " + error);
      }
    }
    setDialogOpen(false);
  };

  const handleDeleteFromDatabase = async () => {
    if (userIdToDelete) {
      try {
        Promise.all([
          deletarAdvogadoPorId(userIdToDelete).then(
            (response: AxiosResponse<Response>) => {
              if (response) {
                window.location.reload();
                toast.success("Advogado(a) deletado(a) com sucesso!");
              }
            },
          ),
        ]);
      } catch (error) {
        toast.error("Erro ao deletar advogado: " + error);
      }
    }
    setDialogOpen(false);
  };

  const handleCancel = () => {
    setDialogOpen(false);
  };

  const handleClose = () => {
    setDialogOpen(false);
  };

  const columns: GridColDef[] = [
    { field: "id", headerName: "ID", width: 90 },
    { field: "nome", headerName: "Nome", width: 230 },
    { field: "oab", headerName: "OAB", width: 150 },
    {
      field: "ativo",
      headerName: "Ativo",
      width: 130,
      renderCell: (params) => {
        return (
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
            }}
          >
            <Box
              sx={{
                color: "white",
                borderRadius: 10,
                textAlign: "center",
                backgroundColor: params.value === "S" ? "green" : "#FA3F17",
                width: 120,
                height: 22,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              {params.value === "S" ? "ATIVO" : "INATIVO"}
            </Box>
          </div>
        );
      },
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      renderCell: (params) => {
        return [
          <Tooltip
            title="Editar Advogado"
            arrow
            key={`${params.row.id}-edit-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-edit`}
              icon={<EditIcon />}
              label="Editar"
              className="textPrimary"
              color="inherit"
              onClick={() => handleEditClick(params.row)}
            />
          </Tooltip>,
          <Tooltip
            title="Excluir"
            arrow
            key={`${params.row.id}-delete-tooltip`}
          >
            <GridActionsCellItem
              key={`${params.row.id}-delete`}
              icon={<DeleteIcon />}
              label="Excluir"
              onClick={() => handleDeleteClick(params.id.toString())}
              color="inherit"
            />
          </Tooltip>,
        ];
      },
    },
  ];

  const handleSelectionChange = (selectionModel: GridRowSelectionModel) => {
    //const selectedIds = selectionModel as number[];
    //const selected = advogados.filter((adv) => selectedIds.includes(adv.id));
    //onSelectionChange(selected);
  };

  return (
    <Card>
      <div style={{ height: 300, width: "100%" }}>
        <DataGrid
          rows={advogados}
          columns={columns}
          onRowSelectionModelChange={handleSelectionChange}
          initialState={{
            pagination: {
              paginationModel: { pageSize: 5 },
            },
          }}
          pageSizeOptions={[5, 10, 25]}
        />
      </div>
      <DeleteAdvogadoDialog
        isOpen={dialogOpen}
        title="Confirmar Exclusão"
        content="Você deseja excluir este advogado?"
        onDeleteFromProcess={handleDeleteFromProcess}
        onDeleteFromDatabase={handleDeleteFromDatabase}
        onCancel={handleCancel}
        onClose={handleClose}
      />
    </Card>
  );
};

export default AdvogadoGrid;
