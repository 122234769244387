import {
  Box,
  Container,
  Grid,
  Typography,
  Button,
  CircularProgress,
} from "@mui/material";
import { FC, useState, ChangeEvent, FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { v4 as uuidv4 } from "uuid";

import { Layout } from "../../components/layout";
import ProcessosTable from "../../components/processosTable";
import { uploadArquivo } from "../../services/importar";
import { adicionarProcessos } from "../../services/processo";
import type {
  ResponseDTO,
  Advogado,
  ProcessoDTO,
  AxiosResponse,
} from "../../types";

const HomeImportar: FC = () => {
  const navigate = useNavigate();
  const [file, setFile] = useState<File | null>(null);
  const [responseText, setResponseText] = useState<string | null>(null);
  const [processos, setProcessos] = useState<ProcessoDTO[]>([]);
  const [loadingUpload, setLoadingUpload] = useState<boolean>(false);
  const [loadingAdd, setLoadingAdd] = useState<boolean>(false);

  const handleFileChange = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files.length > 0) {
      setFile(e.target.files[0]);
    } else {
      setFile(null);
    }
  };

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (file) {
      setLoadingUpload(true);
      try {
        const response: AxiosResponse<ResponseDTO> = await uploadArquivo(file);
        const data = response.data;

        if (data.response) {
          const processosComId =
            data.processos?.map((processo) => ({
              ...processo,
              id: uuidv4(),
            })) || [];

          setResponseText(data.response);
          setProcessos(processosComId);
          toast.success("Arquivo importado com sucesso!");
        } else {
          toast.error(data.response);
        }
      } catch (error: any) {
        let errorMessage = "";
        if (
          error.response &&
          error.response.data &&
          error.response.data.response
        ) {
          errorMessage += error.response.data.response;
        } else {
          errorMessage += error.message;
        }
        toast.error(errorMessage);
      } finally {
        setLoadingUpload(false);
      }
    } else {
      toast.error("Nenhum arquivo selecionado.");
    }
  };

  const handleConfirm = async () => {
    setLoadingAdd(true);
    try {
      const processosParaEnvio = processos.map((processo) => ({
        ...processo,
        id: null,
      }));

      const response: AxiosResponse<ResponseDTO> =
        await adicionarProcessos(processosParaEnvio);
      const data = response.data;
      if (data.response) {
        toast.success("Processos importados com sucesso!");
        navigate(-1);
      } else {
        toast.error(data.response);
      }
    } catch (error: any) {
      let errorMessage = "";
      if (
        error.response &&
        error.response.data &&
        error.response.data.response
      ) {
        errorMessage += error.response.data.response;
      } else {
        errorMessage += error.message;
      }
      toast.error(errorMessage);
    } finally {
      setLoadingAdd(false);
    }
  };

  const updateProcessoAdvogado = (
    processoIndex: number,
    advogadoIndex: number,
    advogado: Advogado,
  ) => {
    setProcessos((prevProcessos) => {
      const novosProcessos = [...prevProcessos];

      if (novosProcessos[processoIndex]) {
        novosProcessos[processoIndex].advogados[advogadoIndex] = advogado;
      }
      return novosProcessos;
    });
  };

  const handleDeleteClick = (row: ProcessoDTO) => {
    const idString = row.id;

    setProcessos((prevProcessos) =>
      prevProcessos.filter((processo) => processo.id !== idString),
    );
  };

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 8,
        }}
      >
        <Container maxWidth="xl">
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <Typography variant="h4">Importar arquivo .pdf TJSP</Typography>
            </Grid>
            <Grid item xs={12}>
              <form onSubmit={handleSubmit}>
                <input type="file" onChange={handleFileChange} />
                {responseText && (
                  <Typography variant="body1" sx={{ mt: 2 }}>
                    {responseText}
                  </Typography>
                )}
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="primary"
                    type="submit"
                    disabled={loadingUpload}
                  >
                    {loadingUpload ? <CircularProgress size={24} /> : "Upload"}
                  </Button>
                </Box>
                <ProcessosTable
                  processos={processos}
                  onDeleteClick={handleDeleteClick}
                  onUpdateProcessoAdvogado={updateProcessoAdvogado}
                />
                <Box mt={2}>
                  {processos.length > 0 && (
                    <Button
                      variant="contained"
                      color="success"
                      disabled={loadingAdd}
                      onClick={handleConfirm}
                      sx={{ ml: 2 }}
                    >
                      Confirmar e Salvar
                    </Button>
                  )}
                </Box>
              </form>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </Layout>
  );
};

export default HomeImportar;
