import {
  Box,
  Container,
  Stack,
  Typography,
  Button,
  SvgIcon,
} from "@mui/material";
import React, { FC, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";

import { Layout } from "../../components/layout";
import { Search } from "../../components/search";
import { TableEmailsEnviados } from "../../components/tableEmailsEnviados";
import useAuth from "../../hooks/useAuth";
import { deletarAdvogadoPorId } from "../../services/advogado";
import { recuperarTodosEmailsEnviados } from "../../services/emailsEnviados";
import { EmailsEnviados, AxiosResponse, Response } from "../../types";

const ListagemEmailsEnviados: FC = () => {
  const navigate = useNavigate();
  const { user, signout } = useAuth();
  const [searchTerm, setSearchTerm] = useState("");
  const [emailsEnviados, setEmailsEnviados] = useState<EmailsEnviados[]>([]);

  const fetchEmailsEnviados = async () => {
    try {
      const response = await recuperarTodosEmailsEnviados();
      if (response) {
        setEmailsEnviados(response.data);
      }
    } catch (error: any) {
      if (error.response && error.response.status === 401) {
        toast.info("Desconectado por inatividade");
        signout();
        navigate("/");
      } else {
        toast.error("Erro ao recuperar todos os usuários:" + error);
      }
    }
  };

  const fetchData = async () => {
    if (user?.idUsuario) {
      await Promise.all([fetchEmailsEnviados()]);
    }
  };

  useEffect(() => {
    if (user?.idUsuario) {
      fetchData();
    }
  }, [user]);

  const handleSearchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchTerm(event.target.value);
  };

  const handleAdd = () => {
    navigate(`/advogado`);
  };

  const handleDelete = async (id: string) => {
    try {
      Promise.all([
        deletarAdvogadoPorId(id).then((response: AxiosResponse<Response>) => {
          if (response) {
            fetchData();
            toast.success("Advogado(a) deletado(a) com sucesso!");
          }
        }),
      ]);
    } catch (error) {
      toast.error("Erro ao deletar advogado: " + error);
    }
  };

  const filteredData = emailsEnviados.filter(
    (item) =>
      item.titulo.toLowerCase().includes(searchTerm.toLowerCase()) ||
      item.mensagem.toLowerCase().includes(searchTerm.toLowerCase()),
  );

  return (
    <Layout>
      <Box
        component="main"
        sx={{
          flexGrow: 1,
          py: 3,
        }}
      >
        <Container maxWidth="xl">
          <Stack spacing={3}>
            <Stack direction="row" justifyContent="space-between" spacing={4}>
              <Stack spacing={1}>
                <Typography variant="h4">E-Mails Enviados</Typography>
              </Stack>
            </Stack>
            <Search
              placeholder={"Pesquisar por Título ou Mensagem"}
              onChange={handleSearchChange}
            />
            <TableEmailsEnviados items={filteredData} onDelete={handleDelete} />
          </Stack>
        </Container>
      </Box>
    </Layout>
  );
};

export default ListagemEmailsEnviados;
