import {
  Delete as DeleteIcon,
  Search as SearchIcon,
  Description as DescriptionIcon,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip } from "@mui/material";
import { DataGrid, GridActionsCellItem, GridColDef } from "@mui/x-data-grid";
import React, { useState } from "react";

import AdvogadoDetailsDialog from "../components/advogadoDetailsDialog";
import ProcessoContentDialog from "../components/processoContentDialog";
import type { ProcessoDTO, Advogado } from "../types";
import AdvogadoSelectionDialog from "./AdvogadoSelectionDialog";

interface ProcessosTableProps {
  processos: ProcessoDTO[];
  onEditFast?: (row: ProcessoDTO) => void;
  onEditClick?: (row: ProcessoDTO) => void;
  onDeleteClick: (row: ProcessoDTO) => void;
  onUpdateProcessoAdvogado: (
    processoIndex: number,
    advogadoIndex: number,
    advogado: Advogado,
  ) => void;
}

const ProcessosTable: React.FC<ProcessosTableProps> = ({
  processos,
  onEditFast,
  onEditClick,
  onDeleteClick,
  onUpdateProcessoAdvogado,
}) => {
  const [selectedAdvogado, setSelectedAdvogado] = useState<Advogado | null>(
    null,
  );
  const [selectedProcessoContent, setSelectedProcessoContent] = useState<
    string | null
  >(null);
  const [selectedAdvogadoIndex, setSelectedAdvogadoIndex] = useState<
    number | null
  >(null);
  const [selectedProcessoIndex, setSelectedProcessoIndex] = useState<
    number | null
  >(null);
  const [advogadoDialogOpen, setAdvogadoDialogOpen] = useState(false);
  const [conteudoDialogOpen, setConteudoDialogOpen] = useState(false);

  const handleAdvogadoDialogClose = () => {
    setAdvogadoDialogOpen(false);
    setSelectedAdvogado(null);
  };

  const handleConteudoClickOpen = (conteudo: string) => {
    setSelectedProcessoContent(conteudo);
    setConteudoDialogOpen(true);
  };

  const handleConteudoDialogClose = () => {
    setConteudoDialogOpen(false);
    setSelectedProcessoContent(null);
  };

  const handleAdvogadoIconClick = (
    processoNumero: string,
    advogadoIndex: number,
  ) => {
    const processoIndex = processos.findIndex(
      (processo) => processo.numero === processoNumero,
    );

    if (processoIndex !== -1) {
      setSelectedProcessoIndex(processoIndex);
      setSelectedAdvogadoIndex(advogadoIndex);
      setAdvogadoDialogOpen(true);
    } else {
      console.error("Processo não encontrado.");
    }
  };

  const handleAdvogadoSelect = (advogado: Advogado) => {
    if (selectedProcessoIndex !== null && selectedAdvogadoIndex !== null) {
      onUpdateProcessoAdvogado(
        selectedProcessoIndex,
        selectedAdvogadoIndex,
        advogado,
      );
    } else {
      console.error(
        "Índice do processo ou do advogado selecionado é inválido.",
      );
    }
    setSelectedProcessoIndex(null);
    setSelectedAdvogadoIndex(null);
  };

  const columns: GridColDef[] = [
    {
      field: "numero",
      headerName: "Número",
      width: 230,
      headerAlign: "center",
    },
    {
      field: "conteudo",
      headerName: "Conteúdo",
      width: 80,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <Tooltip title="Ver Conteúdo Completo">
            <IconButton
              onClick={() => handleConteudoClickOpen(params.row.conteudo)}
            >
              <DescriptionIcon />
            </IconButton>
          </Tooltip>
        </div>
      ),
    },
    {
      field: "advogado1",
      headerName: "Advogado 1",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{params.row.advogados[0]?.nome || "Não selecionado"}</span>
          <IconButton
            onClick={() => handleAdvogadoIconClick(params.row.numero, 0)}
          >
            <SearchIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "advogado2",
      headerName: "Advogado 2",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{params.row.advogados[1]?.nome || "Não selecionado"}</span>
          <IconButton
            onClick={() => handleAdvogadoIconClick(params.row.numero, 1)}
          >
            <SearchIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "advogado3",
      headerName: "Advogado 3",
      width: 250,
      headerAlign: "center",
      renderCell: (params) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span>{params.row.advogados[2]?.nome || "Não selecionado"}</span>
          <IconButton
            onClick={() => handleAdvogadoIconClick(params.row.numero, 2)}
          >
            <SearchIcon />
          </IconButton>
        </div>
      ),
    },
    {
      field: "acoes",
      type: "actions",
      headerName: "Opções",
      width: 160,
      headerAlign: "center",
      renderCell: (params) => [
        <Tooltip title="Excluir" arrow key={`${params.row.id}-delete-tooltip`}>
          <GridActionsCellItem
            key={`${params.row.id}-delete`}
            icon={<DeleteIcon />}
            label="Excluir"
            onClick={() => onDeleteClick(params.row)}
            color="inherit"
          />
        </Tooltip>,
      ],
    },
  ];

  return (
    <>
      <Box sx={{ height: 550, width: "100%" }}>
        <DataGrid
          rows={processos}
          columns={columns}
          checkboxSelection
          pagination
          getRowId={(row) => row.numero}
        />
      </Box>
      <AdvogadoDetailsDialog
        advogado={selectedAdvogado}
        open={advogadoDialogOpen}
        onClose={handleAdvogadoDialogClose}
      />
      <AdvogadoSelectionDialog
        open={advogadoDialogOpen}
        onClose={() => setAdvogadoDialogOpen(false)}
        onSelect={handleAdvogadoSelect}
      />
      <ProcessoContentDialog
        conteudo={selectedProcessoContent || ""}
        open={conteudoDialogOpen}
        onClose={handleConteudoDialogClose}
      />
    </>
  );
};

export default ProcessosTable;
