import type { AxiosResponse, EmailsEnviados } from "../../types";
import { painelApi } from "../painelApi";

const PATH_V1_EMAILS = "/v1/email";

export async function recuperarTodosEmailsEnviados(): Promise<
  AxiosResponse<EmailsEnviados[]>
> {
  return await painelApi.get(PATH_V1_EMAILS);
}
